const historicoStore = {
    state() {
        return {
            historico: '',
        };
    },
    mutations: {
        setHistorico(state, payload) {
            state.historico = payload;
        },
        setNovoHistorico(state, payload) {
            let historico = state.historico;
            payload.pedidos.forEach((pedido) => {
                historico.push(pedido);
            });
        },
    },
    actions: {
        setHistorico(context, payload) {
            context.commit('setHistorico', payload);
        },
        setNovoHistorico(context, payload) {
            context.commit('setNovoHistorico', payload);
        },
    },
    getters: {
        getHistorico(state) {
            return state.historico;
        },

        getHistoricoPedidos(state) {
            let prod = state.historico;

            let filtrados = prod.filter((element) => {
                return element.status != 'Pendente';
            });

            return filtrados;
        },

        getHistoricoCategoria(state) {
            let prod = state.historico;

            let filtrados = prod.filter((element) => {
                return element.status != 'Pendente';
            });

            return filtrados.reduce(function (key, element) {
                key[element.loja] = key[element.loja] || [];
                key[element.loja].push(element);
                return key;
            }, Object.create(null));
        },

        getTotalHistorico(state) {
            let prod = state.historico;

            let filtrados = prod.filter((element) => {
                return element.status != 'Pendente';
            });

            return filtrados.length;
        },
    },
};

export default historicoStore;
